

































































import { defineComponent } from '@vue/composition-api';
import { useErrorModal } from '@/modules/error';

export default defineComponent({
  name: 'KOErrorModal',
  setup() {
    const {
      active,
      error,
      close,
      getTitleFromErrorCode,
      getMessageFromErrorCode,
      message,
      reloadOption,
      reload,
    } = useErrorModal();

    return {
      close,
      active,
      getTitleFromErrorCode,
      getMessageFromErrorCode,
      error,
      message,
      reloadOption,
      reload,
    };
  },
});
